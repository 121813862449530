.ais-Pagination .ais-Pagination-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 300px;
  list-style: none;
}

.search-dialog article {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding: 8px 1rem;
  margin: 0 auto;
  text-align: left;
  flex-wrap: wrap;
  border-bottom: 2px solid #eaeaea; */
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-auto-rows: auto;
  grid-gap: 0.5rem;
  padding: 1rem;
  border-bottom: 2px solid #eaeaea;
  /* left align all data inside divs inside article */
  align-items: center;
  justify-content: center;

  
}
.search-dialog article > div:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.search-dialog article > div:first-of-type {
  /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%; */
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "title author date actions";
}
.search-dialog article > div:first-of-type > div {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr; 
  grid-template-columns: 1fr;
}
.search-dialog article > div:first-of-type > div div {
  border-bottom: 1px solid #eaeaea;

}

.search-input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0;
  outline: none;
}

.search-input:focus {
  outline: none;
}

.search-box-root {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  margin: auto;
  text-align: left;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  width: 100%;
}

.search-loading-indicator,
.search-reset-icon,
.search-submit {
  width: 37px;
  height: 100%;
  border: none;
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0;
  outline: none;
}

.search-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  text-align: left;
  flex-wrap: nowrap;
  border-bottom: 2px solid #eaeaea;
  align-content: center;
  align-items: center;
  width: 100%;
}
