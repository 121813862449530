@keyframes leftright {
  0% {
    background-position-x: 100%;
}
50% {
    background-position-x: 0;
  }
100% {
    background-position-x: 100%;
  }
}
    
body {
    background-position-x: 100%;
    background-position-y: 2%;
    animation: infinite 60s leftright linear;
    /* backdrop-filter: blur(5px); */
}