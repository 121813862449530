tr.gameHead th div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
}

tr.gameHead th div span:first-of-type {
    margin-right: 8px;
    white-space: nowrap;
}

.c3-axis-y-label {
    padding: 20px !important;
}

#legend li::marker {
    content: "\25A0";
    font-size: 1.5rem;
    /* color: black; */
    /* vertical-align: ; */
}

#legend {
    list-style-type: square;
    font-weight: bold;
    margin-bottom: -75px;
}
#legend li span {
    margin-left: 5px;
    position: relative;
    top: -2px;
}